<template>
  <div>

    <!-- S : *********** MainLayer ********************** : S  -->
    <h1>동단위 검색</h1>
    <input-search-component :input-placeholder="inputPlaceholder" />

    <h1>에너지 소비량</h1>
    <select-use-component />

    <color-legend-component />
    <!-- E : *********** MainLayer ********************** : E  -->
    <hr>

    <h1>구 클릭</h1>
    <map-area-engy-use-component
      :modal-title="modalTitle"
      :modal-field="modalField"
      :modal-items="modalItems"
    />
    <br>
    <h1>동 클릭</h1>
    <map-area-engy-use-component
      :modal-title="modalTitle_1"
      :modal-field="modalField_1"
      :modal-items="modalItems_1"
    />

    <hr>

    <!-- S : *********** BuildBasicInfo ********************** : S  -->
    <h1>건물 기본 팝업</h1>
    <build-basic-pop-component />
    <!-- E : *********** BuildBasicInfo ********************** : E  -->

    <hr>

    <!-- S : *********** EnergyUsePop, EchartEnergy ********************** : S  -->
    <h1>에너지 소비량 팝업</h1>
    <energy-use-pop-component />
    <!-- E : *********** EnergyUsePop, EchartEnergy ********************** : E  -->

    <hr>

    <h1>올가미 선택 팝업</h1>
    <lasso-selection-component />

    <hr>

    <h1>업종 검색 팝업</h1>
    <business-search-component />
  </div>

</template>

<script>

import ColorLegendComponent from "@/views/commonLayer/MainLayerPop/ColorLegendComponent";
// import MainUseComponent from "@/views/commonLayer/MainUseComponent";
import MapAreaEngyUseComponent from "@/views/commonLayer/MapAreaEngyUseComponent";
import EnergyUsePopComponent from "@/views/commonLayer/EnergyUsePop/EnergyUsePopComponent";
import LassoSelectionComponent from "@/views/commonLayer/LassoSelectionComponent";
import BusinessSearchComponent from "@/views/commonLayer/BusinessSearchComponent";
import BuildBasicPopComponent from "@/views/commonLayer/BuildBasicInfo/BuildBasicPopComponent";
import SelectUseComponent from "~/building/SelectUseComponent";
import InputSearchComponent from "~/building/InputSearchComponent";

export default {

  components: {
    BusinessSearchComponent,
    LassoSelectionComponent,
    EnergyUsePopComponent,
    BuildBasicPopComponent,

    MapAreaEngyUseComponent,
    // MainUseComponent,
    ColorLegendComponent,
    SelectUseComponent,
    InputSearchComponent,
  },
  data() {
    return {
      inputPlaceholder: '동단위로 검색해주세요',
      modalTitle: '▶ 관악구 통계 요약',
      modalField: [
        { key: 'dong', label: '동' },
        { key: 'buildCnt', label: '건물 수' },
        { key: 'buildEnergyUse', label: '건물 에너지 소비량' },
        { key: 'buildAvgFirEnergyUse', label: '건물 평균 1차 에너지 소비량' },
        { key: 'buildAvgCo2', label: '건물 평균 CO2 배출량' },
      ],

      modalItems: [
        {
          dong: '남현동',
          buildCnt: '10',
          buildEnergyUse: '100',
          buildAvgFirEnergyUse: '250',
          buildAvgCo2: '10',
        },
        {
          dong: '신림동',
          buildCnt: '10',
          buildEnergyUse: '100',
          buildAvgFirEnergyUse: '250',
          buildAvgCo2: '10',
        },
        {
          dong: '봉천동',
          buildCnt: '10',
          buildEnergyUse: '100',
          buildAvgFirEnergyUse: '250',
          buildAvgCo2: '10',
        },
      ],

      modalTitle_1: '신림동 통계 요약',
      modalField_1: [
        { key: 'buildType', label: '건물 유형' },
        { key: 'buildCnt', label: '건물 수' },
        { key: 'buildEnergyUse', label: '건물 에너지 소비량' },
        { key: 'buildAvgFirEnergyUse', label: '건물 평균 1차 에너지 소비량' },
        { key: 'buildAvgCo2', label: '건물 평균 CO2 배출량' },
      ],
      modalItems_1: [
        {
          buildType: '업무 시설',
          buildCnt: '10',
          buildEnergyUse: '100',
          buildAvgFirEnergyUse: '250',
          buildAvgCo2: '10',
        },
        {
          buildType: '판매 시설',
          buildCnt: '10',
          buildEnergyUse: '100',
          buildAvgFirEnergyUse: '250',
          buildAvgCo2: '10',
        },
        {
          buildType: '의료 시설',
          buildCnt: '10',
          buildEnergyUse: '100',
          buildAvgFirEnergyUse: '250',
          buildAvgCo2: '10',
        },
      ],

    }
  },

  mounted() {

  },
  methods: {

  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
//.body-area {
//  font-family: Helvetica Neue, Arial, sans-serif;
//}
</style>

<template>
  <b-row>
    <b-col cols="2">
      <b-form-group
        label="색상 범례"
        label-cols-md="4"
      />
    </b-col>
    <b-col cols="10">
      <div class="d-flex justify-content-start flex-wrap">
        <div class="text-center bg-secondary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
          <span>~25%</span>
        </div>
        <div class="text-center bg-success colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
          <span>~50%</span>
        </div>
        <div class="text-center bg-warning colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
          <span>~75%</span>
        </div>
        <div class="text-center bg-danger colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
          <span>~100%</span>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
  },

}
</script>
<style lang="scss" scoped>
.colors-list {
  li {
    padding: 0.4rem;
    svg {
      margin-right: 0.25rem;
    }
  }
}
.bg-secondary {
  background-color: #e8c3c3 !important;
}
.bg-success {
  background-color: #d99090 !important;
}
.bg-warning {
  background-color: #cb5759 !important;
}
.bg-danger {
  background-color: #9a2c2e !important;
}
</style>

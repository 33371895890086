<template>
  <div class="body-style">
    ＊일반표제부와 매칭된 계량기 현황
    <b-table
      striped
      :items="items"
      :fields="fields"
      responsive="sm"
    />
    ＊총괄표제부와 매칭된 계량기 현황
    <b-table
      striped
      :items="items"
      :fields="fields"
      responsive="sm"
    />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'rowNum', label: 'No.',
        },
        {
          key: 'buildName', label: '건물명',
        },
        {
          key: 'totalArea', label: '연면적',
        },
        {
          key: 'meterPersonalNumber', label: '계량기 고객번호',
        },
        {
          key: 'energyType', label: '에너지원 구분',
        },
        {
          key: 'purpose', label: '용도',
        },
        {
          key: 'supplyAgency', label: '공급기관',
        }

      ],
      items: [
        {
          rowNum: '1',
          buildName: '강릉원주대학교',
          totalArea: '8,669.76',
          meterPersonalNumber: '0000000014007018000',
          energyType: '도시가스',
          purpose: '일반용_영업용1',
          supplyAgency: '참빛영동도시가스',
        },

      ],

    }
  },
}
</script>

<style scoped>
.body-style {

  font-family: Helvetica Neue, Arial, sans-serif;
}

</style>

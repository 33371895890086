<template>
  <div>
    <!-- trigger button -->
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="onClickEnergyUse('41287-100223575')"
      >
        에너지 소비량 팝업
      </b-button>
    </div>

    <b-modal
      v-model="modalType"
      size="xl"
      centered
      title="▶ 에너지 소비량 팝업"
      ok-title="확인"
      ok-only
    >
      <b-tabs
        content-class="mt-2"
        class="modal-style"
        justified
      >
        <b-tab
          title="에너지 소비량"
          active
        >
          <energy-use-info-component
            :mgm-bld-pk="mgmBldPk"
            :engy-kind-cd="engyKindCd"
          />
        </b-tab>
        <b-tab
          title="계량기 정보"
        >
          <energy-meter-info-component />
        </b-tab>

      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BTabs, BTab } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EnergyUseInfoComponent from "@/views/commonLayer/EnergyUsePop/EnergyUseInfoComponent";
import EnergyMeterInfoComponent from "@/views/commonLayer/EnergyUsePop/EnergyMeterInfoComponent";

export default {
  components: {
    EnergyMeterInfoComponent,
    EnergyUseInfoComponent,
    BButton,
    BModal,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      modalType: false,
      mgmBldPk: null,
      engyKindCd: '11' // 테스트고정값
    }
  },
  methods: {
    onClickEnergyUse(mgmBldPk) {
      this.modalType = !this.modalType
      this.mgmBldPk = mgmBldPk
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

<template>
  <div class="body-style">
    <!-- trigger button -->
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="modalType = !modalType"
      >
        업종 검색
      </b-button>
    </div>

    <b-modal
      v-model="modalType"
      size="xl"
      centered
      title="업종검색"
      ok-title="확인"
      ok-only
    >
      <b-row>
        <b-col cols="2">
          <b-form-group
            label="카테고리"
          />
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-select
              :value="selected"
              :options="selectOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="업종명"
          />
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-input
              id="item-name"
              type="text"
              placeholder="업종검색 입력"
            />
          </b-form-group>
        </b-col>
        <!-- Search Button -->
        <b-col cols="2">
          <b-button variant="relief-primary">
            검색
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            striped
            :items="items_1"
            :fields="fields_1"
            responsive="sm"
            class="body-style"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          ▶ 선택한 업종
          <b-form-textarea
            id="textarea-default"
            placeholder="병원, 부속의료기관"
            rows="8"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BTable, BFormTextarea } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BTable,
    BFormTextarea
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      modalType: false,
      selected: null,
      selectOptions: [
        { value: null, text: '전체' },
        { value: 'hospital', text: '병원' },
        { value: 'pharmacy', text: '약국' },
        { value: 'clinic', text: '의원' },
      ],
      fields_1: [
        { key: 'rowNum', label: 'No' },
        { key: 'category', label: '카테고리' },
        { key: 'businessName', label: '업종명' },
        { key: 'choice', label: '선택' },

      ],
      items_1: [{
        rowNum: '1',
        category: '건강',
        businessName: '병원',
        choice: '선택',
      }],
    }
  },
}
</script>
<style scoped>
.body-style {
  font-family: Helvetica Neue, Arial, sans-serif;
}

</style>

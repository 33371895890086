<template>
  <div class="body-style">
    <!-- trigger button -->
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="modalType = !modalType"
      >
        올가미 팝업
      </b-button>
    </div>

    <b-modal
      v-model="modalType"
      size="xl"
      centered
      title="에너지 소비량 팝업"
      ok-title="확인"
      ok-only
    >
      ▶ 용도별 통계 요약
      <b-table
        striped
        :items="items"
        :fields="fields"
        responsive="sm"
        class="body-style"
      />
      ▶ 선택 건물 리스트
      <b-table
        striped
        :items="items_1"
        :fields="fields_1"
        responsive="sm"
        class="body-style"
      />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BTable
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      modalType: false,

      fields: [
        { key: 'buildType', label: '건물 유형' },
        { key: 'buildCnt', label: '건물 수' },
        { key: 'buildEnergyUse', label: '건물 에너지 소비량' },
        { key: 'buildAvgFirEnergyUse', label: '건물 평균 1차 에너지 소비량' },
        { key: 'buildAvgCo2', label: '건물 평균 CO2 배출량' },
      ],
      items: [{
        buildType: '업무 시설',
        buildCnt: '10',
        buildEnergyUse: '100',
        buildAvgFirEnergyUse: '250',
        buildAvgCo2: '10',
      }],
      fields_1: [
        { key: 'buildName', label: '건물명' },
        { key: 'buildAddress', label: '주소' },
        { key: 'buildType', label: '건물 유형' },
        { key: 'buildArea', label: '연먼적' },
        { key: 'buildEnergyUse', label: '에너지 소비량' },
        { key: 'buildAvgFirEnergyUse', label: '1차 에너시 소비량' },
        { key: 'buildAvgCo2', label: '건물 평균 CO2 배출량' },
      ],
      items_1: [{
        buildName: '업무 시설',
        buildAddress: '10',
        buildType: '100',
        buildArea: '250',
        buildEnergyUse: '10',
        buildAvgFirEnergyUse: '10',
        buildAvgCo2: '10',
      }],
    }
  },
}
</script>
<style>
.body-style {
  font-family: Helvetica Neue, Arial, sans-serif;
}

</style>

<template>
  <div>
    <h3>1) 총괄 표제부</h3>
    <h5>•소비랑 분리 (단위환산, 1MJ=0.278kWh, 1Mcal = 1.163kWh)</h5>
    <b-row>
      <b-col cols="12">
        <b-table-simple
          small
          responsive
          striped
          class="row-style"
        >
          <colgroup>
            <col>
            <col>
          </colgroup>
          <b-thead head-variant="light">
            <b-tr>
              <b-th colspan="3">
                전기
              </b-th>
              <b-th colspan="3">
                가스
              </b-th>
              <b-th colspan="3">
                열
              </b-th>
              <b-th colspan="3">
                합계
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>난방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].elechetgqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>난방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].gashetgqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>난방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].hetghetgqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>난방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ (getEnergyUseData[0].elechetgqty + getEnergyUseData[0].gashetgqty + getEnergyUseData[0].hetghetgqty) | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
            </b-tr>
            <b-tr>
              <b-td>냉방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].elecaconqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>냉방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].gasaconqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>냉방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].hetgaconqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>냉방</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ (getEnergyUseData[0].elecaconqty + getEnergyUseData[0].gasaconqty + getEnergyUseData[0].hetgaconqty) | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
            </b-tr>
            <b-tr>
              <b-td>기저</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].elecbaseqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>기저</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].gasbaseqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>기저</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ getEnergyUseData[0].hetgbaseqty | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>기저</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ (getEnergyUseData[0].elecbaseqty + getEnergyUseData[0].gasbaseqty + getEnergyUseData[0].hetgbaseqty ) | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
            </b-tr>
            <b-tr>
              <b-td>합계</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ (getEnergyUseData[0].elechetgqty + getEnergyUseData[0].elecaconqty + getEnergyUseData[0].elecbaseqty ) | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>합계</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ (getEnergyUseData[0].gashetgqty + getEnergyUseData[0].gasaconqty + getEnergyUseData[0].gasbaseqty ) | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
              <b-td>합계</b-td>
              <b-td>0</b-td>
              <b-td>kWh</b-td>
              <b-td>합계</b-td>
              <b-td v-if="getEnergyUseData[0]">
                {{ (getEnergyUseData[0].elechetgqty + getEnergyUseData[0].elecaconqty + getEnergyUseData[0].elecbaseqty + getEnergyUseData[0].gashetgqty + getEnergyUseData[0].gasaconqty + getEnergyUseData[0].gasbaseqty ) | makeComma }}
              </b-td>
              <b-td>kWh</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row>

      <b-col cols="6">
        <echart-stacked-area
          :mgm-bld-pk="mgmBldPk"
          :energy-chart-type="engyKind"
        />
      </b-col>
      <b-col cols="6">
        <echart-stacked-area
          :mgm-bld-pk="mgmBldPk"
          :energy-chart-type="clsfKind"
        />
      </b-col>

    </b-row>
  </div>
</template>
<script>
import { BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from "vuex";

import EchartStackedArea from '../EchartEnergy/EchartStackedArea.vue'
import EchartArea from '../EchartEnergy/EchartArea.vue'

export default {
  components: {

    EchartStackedArea,
    EchartArea,

    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      // 넘길 변수 설정
      clsfKind: "clsfKind",
      engyKind: "engyKind"
    }
  },
  computed: {
    ...mapGetters({
      getEnergyUseData: "main/getEnergyUseData",
    }),
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.$store.dispatch("main/FETCH_ENERGY_USE_DATA", { mgmBldPk: this.mgmBldPk });
  }
}
</script>
<style scoped>
.row-style {
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-family: Helvetica Neue, Arial, sans-serif;
}
th, td {
  border: 1px solid #cccccc;
  margin: auto;
  text-align: center;
}
</style>

<template>
  <div>
    <!-- trigger button -->
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="onClickBuildInfo('41287-100223575')"
      >
        건물 기본 팝업
      </b-button>
    </div>

    <b-modal
      v-model="modalType"
      size="xl"
      centered
      title="▶ 건물 기본 팝업"
      ok-title="확인"
      ok-only
    >
      <b-tabs
        content-class="mt-2"
        class="modal-style"
        justified
      >
        <b-tab
          title="건축물 대장"
          active
        >
          <build-basic-info-component :mgm-bld-pk="mgmBldPk" />
        </b-tab>
        <b-tab
          title="층별 개요"
        >
          <build-floor-overview-component :mgm-bld-pk="mgmBldPk" />
        </b-tab>
        <!--        <b-tab
          title="인허가 리스트"
        >
          <build-license-list-component />
        </b-tab>-->
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BTabs, BTab } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import BuildBasicInfoComponent from "@/views/commonLayer/BuildBasicInfo/BuildBasicInfoComponent";
import BuildFloorOverviewComponent from "@/views/commonLayer/BuildBasicInfo/BuildFloorOverviewComponent";
// import BuildLicenseListComponent from "@/views/commonLayer/BuildBasicInfo/BuildLicenseListComponent";

export default {
  components: {
    BButton,
    BModal,
    // BuildLicenseListComponent,
    BuildFloorOverviewComponent,
    BuildBasicInfoComponent,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      modalType: false,
      mgmBldPk: null
    }
  },
  methods: {
    onClickBuildInfo(mgmBldPk) {
      this.modalType = !this.modalType
      this.mgmBldPk = mgmBldPk
    }
  }
}
</script>
<style lang="scss" scoped>
.row-style {
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-family: Helvetica Neue, Arial, sans-serif;
}
.cols-bg {
  background-color: #F2F2F2 !important;
  min-width: 120px;
  padding: 10px 20px;
}
.cols-td {
  min-width: 120px;
  padding: 10px 20px;
}
.row-modal {
  font-family: Helvetica Neue, Arial, sans-serif;
}
</style>

<template>
  <div>
    <!-- trigger button -->
    <div class="demo-inline-spacing">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="onClickMapArea('type','11620')"
      >
        관악구
      </b-button>
    </div>

    <b-modal
      v-model="modalType"
      size="xl"
      centered
      title="▶ 관악구 통계 요약"
      ok-title="확인"
      ok-only
    >
      <b-table
        responsive="sm"
      />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BTable
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    modalItems: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      modalType: false,
      type: null,
      mapId: null
    }
  },
  methods: {
    onClickMapArea(type, mapId) {
      this.modalType = !this.modalType
      this.mapId = mapId
      this.$store.dispatch("main/FETCH_MAP_AREA_DATA", this.mapId);
    }
  },

}
</script>
<style lang="scss">

</style>
